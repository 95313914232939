import { Routes } from '@angular/router';
import { MainComponent } from './pages/main/main.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/auth' },
  {
    path: 'main',
    component: MainComponent,
    children: [
      //{ path: '', loadChildren: () => import('./pages/main/main.routes').then(m => m.ALTA_ROUTES)},
      {
        path: 'sale/new', loadChildren: () => import('./pages/sale/new-sale/new_sale.routes').then(m => m.NEW_SALE)
      },
      {
        path: 'client/alta', loadChildren: () => import('./pages/clients/alta/alta.routes').then(m => m.ALTA_ROUTES)
      },
      {
        path: 'client/all', loadChildren: () => import('./pages/clients/get-all/get-all-routes').then(m => m.GETALL_ROUTES)
      },
      {
        path: 'zone/alta', loadChildren: () => import('./pages/zone/alta/alta.routes').then(m => m.ALTA_ROUTES)
      },
      {
        path: 'zone/all', loadChildren: () => import('./pages/zone/get-all/get-all.routes').then(m => m.GET_ALL_ROUTES)
      },
      {
        path: 'seller/alta', loadChildren: () => import('./pages/seller/alta/alta.routes').then(m => m.ALTA_ROUTES)
      },
      {
        path: 'seller/all', loadChildren: () => import('./pages/seller/all/get-all.routes').then(m => m.GET_ALL_ROUTES)
      },
      {
        path: 'art/alta', loadChildren: () => import('./pages/article/alta-article/article.route.routes').then(m => m.ARTICLE_ROUTES)
      },
      {
        path: 'art/all', loadChildren: () => import('./pages/article/get-all/all-articles/all-article.route.routes').then(m => m.ALL_ARTICLE_ROUTES)
      },
      {
        path: 'art/detail/:id', loadChildren:() => import('./pages/article/detail/detail.routes').then(m => m.DETAIL_ROUTES)
      },
      {
        path: 'vendor/sale', loadChildren:() => import('./pages/despacho/despacho-routes').then(m => m.DESPACHO_ROUTES)
      },
      {
        path: 'vendor-sales/control', loadChildren:() => import('./pages/sales-graph/sales-control/sales-control-routes').then(m => m.SALES_ROUTES)
      },
      {
        path: 'admin-sales/metric', loadChildren:() => import('./pages/sales-graph/metric-admin/metric-admin-routes').then(m => m.METRIC_ROUTES)
      },
      {
        path: 'art/massive', loadChildren:() => import('./pages/article/massive-rise/massive-rise-routes').then(m => m.MASSIVE_ROUTES)
      },
      {
        path: 'vendor/profile', loadChildren:() => import('./pages/Vendor/profile/vendor.routes').then(m => m.VENDOR_ROUTES)
      },
      {
        path: 'owner/profile', loadChildren:() => import('./pages/owner/profile/owner.routes').then(m => m.OWNER_ROUTES)
      },
      {
        path: 'plan/user', loadChildren:() => import('./pages/plan/plan-upgrade/plan.routes').then(m => m.PLAN_ROUTES)
      },
      {
        path: 'plan/payments', loadChildren:() => import('./pages/plan/payments/payments.routes').then(m => m.PAYMENTS_ROUTES)
      },
      {
        path: 'plan/monthly', loadChildren:() => import('./pages/plan/monthly/monthly.routes').then(m => m.MONTLY_ROUTES)
      },
      {
        path: 'admin/users', loadChildren:() => import('./pages/admin/users/users.route.routes').then(m => m.USERS_ROUTES)
      },
      {
        path: 'admin/payment', loadChildren:() => import('./pages/admin/payment/payment.route.routes').then(m => m.PAYMENT_ROUTES)
      }


    ]
  },

  {
    path: 'auth', loadComponent: () => import('./pages/auth/auth.component').then(m => m.AuthComponent)
  },
  {
    path: 'register', loadChildren: () => import('./pages/register/register.routes').then(m => m.REGISTER_ROUTES)
  },
  {
    path:'send-email', loadChildren: () => import('./pages/forward-email/forward-email.routes').then(m => m.FORWARD_EMAIL_ROUTES)
  },
  {
    path: 'start', loadChildren: () => import('./pages/home/start/start.routes').then(m => m.START_ROUTES)
  }

];
