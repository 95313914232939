<nz-layout class="layout">
  <nz-sider nzCollapsible nzBreakpoint="xxl" nzCollapsible nzWidth="180px" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <!-- <div class="sidebar-logo d-flex align-items-center "
      [ngClass]="{'sidebar-propietario': role === 'PROPIETARIO', 'sidebar-vendedor': role === 'VENDEDOR'}">
      <a>
        <img src="assets\img\ventas-icon.png" alt="logo">
      </a>

    </div> -->
    <div class="sidebar-logo d-flex align-items-center justify-content-center"
      [ngClass]="{'sidebar-propietario': role === 'PROPIETARIO', 'sidebar-vendedor': role === 'VENDEDOR'
        ,'sidebar-admin': role === 'ADMIN'
      }">
      <a>
        <!-- <i class="fa-solid fa-chart-pie ventas-icon"></i> -->
        <img class="img-logo" src="assets\img\analitica.png" alt="logo">
      </a>
    </div>

    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li *ngIf="role === 'ADMIN'" nz-submenu nzTitle="Usuarios" nzIcon="team"
      [ngClass]="{'active-submenu-admin': role === 'ADMIN' && isSubmenuActive(['/main/admin/users'])}">
        <ul>
          <li *ngIf="role === 'ADMIN'"[routerLinkActive]="['router-link-active', 'router-link-active-admin']"
            routerLink="/main/admin/users" nzMatchRouter class="li-hover-admin" nz-menu-item>
            <a >Usuarios</a>
          </li>

        </ul>
      </li>
      <li *ngIf="role === 'ADMIN'" nz-submenu nzTitle="Pagos" nzIcon="credit-card"
      [ngClass]="{'active-submenu-admin': role === 'ADMIN' && isSubmenuActive(['/main/admin/payment'])}"
      >
        <ul>
          <li *ngIf="role === 'ADMIN'"[routerLinkActive]="['router-link-active', 'router-link-active-admin']"
          routerLink="/main/admin/payment" nzMatchRouter class="li-hover-admin" nz-menu-item
          >
            <a >Pagos</a>
          </li>
        </ul>
      </li>
      <li *ngIf="role === 'ADMIN'" nz-submenu nzTitle="Métricas" nzIcon="bar-chart">
        <ul>
          <li>
            <a >Métricas</a>
          </li>
        </ul>
      </li>

        <!-- Subir Plan -->
        <li *ngIf="role == 'PROPIETARIO'" nz-submenu nzTitle="Plan" nzIcon="crown" [ngClass]="{

          'active-submenu-propietario': role === 'PROPIETARIO' && isSubmenuActive(['/main/plan/user','/main/plan/payments','/main/plan/monthly'])
          }">
          <ul>
            <li
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/plan/user"
              nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/plan/user')" class="li-hover-propietario" nz-menu-item>
              <a>Planes</a>
            </li>

            <!-- Enlace para Pagos Realizados -->
            <li
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']"
              routerLink="/main/plan/payments" nzMatchRouter (click)="navigateAndShowSpinner('/main/plan/payments')"
              class="li-hover-propietario" nz-menu-item>
              <a>Pagos realizados</a>
            </li>

            <!-- Enlace para Pagos Mensuales -->
            <li
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']"
              routerLink="/main/plan/monthly" nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/plan/monthly')" class="li-hover-propietario" nz-menu-item>
              <a>Pagos mensuales</a> <!-- Texto en inglés -->
            </li>
          </ul>

        </li>

        <!-- Perfil -->
        <li *ngIf="role != 'ADMIN'" nz-submenu nzTitle="Perfil" nzIcon="user" [ngClass]="{
            'active-submenu-vendedor': role === 'VENDEDOR' && isSubmenuActive(['/main/vendor/profile']),
            'active-submenu-propietario': role === 'PROPIETARIO' && isSubmenuActive(['/main/owner/profile'])
          }">
          <ul>

            <li *ngIf="role === 'VENDEDOR'" [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']"
              routerLink="/main/vendor/profile" nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/vendor/profile')" class="li-hover-vendedor" nz-menu-item>
              <a>Vendedor</a>
            </li>
            <li *ngIf="role === 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']"
              routerLink="/main/owner/profile" nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/owner/profile')" class="li-hover-propietario" nz-menu-item>
              <a>Propietario</a>
            </li>

          </ul>
        </li>
        <li *ngIf="role != 'ADMIN'" nz-submenu nzTitle="Ventas" nzIcon="dollar" [ngClass]="{
          'active-submenu-vendedor': role === 'VENDEDOR' && isSubmenuActive(['/main/vendor-sales/control','/main/sale/new','/main/vendor/sale?page=' + page,'/main/vendor/sale?page=' + page + '&search=' + searchQuery,'/main/vendor/sale', '/main/vendor/sale?page='+page+'&year=' +year,'/main/vendor/sale?page='+page+'&startDate='+startDate+'&endDate='+endDate]),
          'active-submenu-propietario': role === 'PROPIETARIO' && isSubmenuActive(['/main/admin-sales/metric'])
        }">
          <ul>
            <li routerLink="/main/sale/new" nzMatchRouter
              [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']"
              [routerLinkActiveOptions]="{ exact: true }" (click)="navigateAndShowSpinner('/main/sale/new')"
              *hasRole="['VENDEDOR']" class="li-hover-vendedor" nz-menu-item>
              <a>Carrito</a>
            </li>
            <li routerLink="/main/vendor/sale" nzMatchRouter
              [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']"
              (click)="navigateAndShowSpinner('/main/vendor/sale')"
              *ngIf="role === 'VENDEDOR'" class="li-hover-vendedor" nz-menu-item>
              <a>Despachar</a>
            </li>
            <li routerLink="/main/admin-sales/metric" nzMatchRouter
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']"
              [routerLinkActiveOptions]="{ exact: true }" (click)="navigateAndShowSpinner('/main/admin-sales/metric')"
              *ngIf="role === 'PROPIETARIO'" class="li-hover-propietario" nz-menu-item>
              <a>Métricas</a>
            </li>
            <li *ngIf="role === 'VENDEDOR'" [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']"
              routerLink="/main/vendor-sales/control" nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/vendor-sales/control')" class="li-hover-vendedor" nz-menu-item>
              <a>Métricas</a>
            </li>
          </ul>
        </li>
        <li *ngIf="role != 'ADMIN'" nz-submenu nzTitle="Crear" nzIcon="plus" [ngClass]="{
            'active-submenu-vendedor': role === 'VENDEDOR' && isSubmenuActive(['/main/client/alta']),
            'active-submenu-propietario': role === 'PROPIETARIO' && isSubmenuActive(['/main/seller/alta','/main/zone/alta','/main/art/alta','/main/art/massive'])
          }">
          <ul>
            <li [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']" routerLink="/main/client/alta"
              nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/client/alta')" *ngIf="role == 'VENDEDOR'" class="li-hover-vendedor"
              nz-menu-item>
              <a>Cliente</a>
            </li>
            <li [routerLinkActive]="['router-link-active', 'router-link-active-propietario']"
              routerLink="/main/seller/alta" nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/seller/alta')" *ngIf="role == 'PROPIETARIO'"
              class="li-hover-propietario" nz-menu-item>
              <a>Vendedor</a>
            </li>
            <li *ngIf="role == 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/zone/alta"
              nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/zone/alta')" class="li-hover-propietario" nz-menu-item>
              <a>Zona</a>
            </li>
            <li *ngIf="role == 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/art/alta"
              nzMatchRouter [routerLinkActiveOptions]="{ exact: true }" (click)="navigateAndShowSpinner('/main/art/alta')"
              class="li-hover-propietario" nz-menu-item>
              <a>Artículo</a>
            </li>
            <li *ngIf="role == 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/art/massive"
              nzMatchRouter [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/art/massive')" class="li-hover-propietario" nz-menu-item>
              <a>Carga masiva</a>
            </li>
          </ul>

        </li>
        <!-- Listar -->
        <li *ngIf="role != 'ADMIN'" nz-submenu nzTitle="Listar" nzIcon="unordered-list" [ngClass]="{
          'active-submenu-vendedor': role === 'VENDEDOR' && isSubmenuActive(['/main/client/all','/main/art/all',  '/main/art/all?page=' + page + '&search=' + searchQuery, '/main/art/all?page=' + page + '&search=' + searchQuery + '&category=' + categoryId, '/main/art/all?page=' + page,'/main/art/all?page=' + page + '&category=' + categoryId ]),
          'active-submenu-propietario': role === 'PROPIETARIO' && isSubmenuActive(['/main/seller/all','/main/seller/all?page=' + page + '&filter=' + searchQuery,'/main/zone/all','/main/zone/all?page='+page+'&search='+searchQuery ,'/main/art/all',  '/main/art/all?page=' + page + '&search=' + searchQuery, '/main/art/all?page=' + page + '&search=' + searchQuery + '&category=' + categoryId, '/main/art/all?page=' + page,'/main/art/all?page=' + page + '&category=' + categoryId ])
        }">
          <ul>
            <li nzMatchRouter [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']"
              routerLink="/main/client/all" [routerLinkActiveOptions]="{ exact: true }"
              (click)="navigateAndShowSpinner('/main/client/all')" *ngIf="role == 'VENDEDOR'" nz-menu-item
              class="li-hover-vendedor">
              <a>Clientes</a>
            </li>
            <li *ngIf="role == 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/seller/all"
              nzMatchRouter (click)="navigateAndShowSpinner('/main/seller/all')" nz-menu-item
              [ngClass]="['router-link-active', role === 'PROPIETARIO'? 'li-hover-propietario':'li-hover-vendedor']">
              <a>Vendedores</a>
            </li>
            <li nzMatchRouter *ngIf="role == 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/zone/all"
              [routerLinkActiveOptions]="{ exact: true }" (click)="navigateAndShowSpinner('/main/zone/all')" nz-menu-item
              [ngClass]="['router-link-active', role === 'PROPIETARIO'? 'li-hover-propietario':'li-hover-vendedor']">
              <a>Zonas</a>
            </li>
            <!-- <li nzMatchRouter
              [routerLinkActive]="['router-link-active', role === 'VENDEDOR' ? 'router-link-active-vendedor' : 'router-link-active-propietario']"

              routerLink="/main/art/all"
              (click)="navigateAndShowSpinner('/main/art/all')"
              nz-menu-item
              [ngClass]="['router-link-active', role === 'PROPIETARIO'? 'li-hover-propietario':'li-hover-vendedor']">
              <a>Artículos</a>
            </li> -->
            <li nzMatchRouter *ngIf="role === 'PROPIETARIO'"
              [routerLinkActive]="['router-link-active', 'router-link-active-propietario']" routerLink="/main/art/all"
              (click)="navigateAndShowSpinner('/main/art/all')" nz-menu-item
              [ngClass]="['router-link-active', 'li-hover-propietario']">
              <a>Artículos</a>
            </li>
            <li nzMatchRouter *ngIf="role === 'VENDEDOR'"
              [routerLinkActive]="['router-link-active', 'router-link-active-vendedor']" routerLink="/main/art/all"
              (click)="navigateAndShowSpinner('/main/art/all')" nz-menu-item
              [ngClass]="['router-link-active', 'li-hover-vendedor']">
              <a>Artículos</a>
            </li>


          </ul>
        </li>

      <!-- Botón para cerrar sesión -->
      <li nz-submenu nzTitle="Salir" nzIcon="logout">
        <ul>
          <li nz-menu-item nzMatchRouter
            [ngClass]="['router-link-active', role === 'PROPIETARIO'? 'li-hover-propietario':'li-hover-vendedor']">
            <a class="a-style" [class.disabled]="isLoading" (click)="!isLoading && logout()">Cerrar sesión</a>
          </li>
        </ul>
      </li>

    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger d-flex justify-content-between align-items-center">
          <span class="trigger" (click)="isCollapsed = !isCollapsed" nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"

            [ngClass]="{
              'trigger-active-vendedor':role === 'VENDEDOR',
              'trigger-active-propietario':role === 'PROPIETARIO',
              'trigger-active-admin':role === 'ADMIN'
            }">
          </span>

          <div *ngIf="role === 'VENDEDOR'">
            <nz-badge [nzCount]="quantityShipped" [nzOverflowCount]="100" nzSize="small">
              <button (click)="navigateAndShowSpinner('/main/vendor/sale')" class="button-ngZorro"
                nzTooltipTitle="Despachar" nzTooltipColor="#05e3f3" nzTooltipPlacement="bottom" nz-button nz-tooltip
                [nzTooltipOverlayStyle]="{ 'font-size': '11px', 'padding': '4px 8px', 'max-width': '100px' }"
                style="background-color: #333; color: #05e3f3;">
                <i class="fa-solid fa-truck-loading"></i>
              </button>
            </nz-badge>
          </div>

          <div *ngIf="role === 'PROPIETARIO' || role === 'ADMIN'" class="rounded-2 "
            [ngClass]="{
              'role-container role-container-responsive': role === 'PROPIETARIO',
              'role-container-admin role-container-responsive-admin': role === 'ADMIN'
            }"

            >
              <span
              [ngClass]="{
                'gradient-text': role === 'PROPIETARIO',
                'gradient-admin': role === 'ADMIN'
              }"
            >{{ role }}</span>
          </div>

          <div *ngIf="role === 'VENDEDOR'">
            <nz-badge [nzCount]="quantityCart.quantity" [nzOverflowCount]="100" nzSize="small">
              <button (click)="navigateAndShowSpinner('/main/sale/new')" class="button-ngZorro"
                nzTooltipTitle="Ver carrito" nzTooltipColor="#05e3f3" nzTooltipPlacement="bottom" nz-button nz-tooltip
                [nzTooltipOverlayStyle]="{ 'font-size': '11px', 'padding': '4px 8px', 'max-width': '100px' }"
                style="background-color: #333; color: #05e3f3;">
                <i class="fa-solid fa-cart-arrow-down"></i>
              </button>
            </nz-badge>
          </div>
        </span>
      </div>

    </nz-header>
    <nz-content class="overflow-scroll">
      <div class="inner-content" style="position: relative;">
        <div *ngIf="isLoadingSpinOff" class="spinner-overlay">

          <svg class="svg-style-off" width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <style>
              .spinner_9y7u {
                animation: spinner_fUkk 2.4s linear infinite;
                animation-delay: -2.4s
              }

              .spinner_DF2s {
                animation-delay: -1.6s
              }

              .spinner_q27e {
                animation-delay: -.8s
              }

              @keyframes spinner_fUkk {
                8.33% {
                  x: 13px;
                  y: 1px
                }

                25% {
                  x: 13px;
                  y: 1px
                }

                33.3% {
                  x: 13px;
                  y: 13px
                }

                50% {
                  x: 13px;
                  y: 13px
                }

                58.33% {
                  x: 1px;
                  y: 13px
                }

                75% {
                  x: 1px;
                  y: 13px
                }

                83.33% {
                  x: 1px;
                  y: 1px
                }
              }
            </style>
            <rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10" />
            <rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10" />
            <rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10" />
          </svg>
        </div>
        <div *ngIf="isLoading" class="spinner-overlay">
          <!-- <i class="fas fa-spinner fa-spin fa-3x text-info"></i> Spinner giratorio -->
          <div class="">
            <div *ngIf="role == 'PROPIETARIO'">
              <svg class="svg-style-propietario" width="44" height="44" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <style>
                  .spinner_zWVm {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_PnZo 1.2s linear infinite
                  }

                  .spinner_gfyD {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_4j7o 1.2s linear infinite;
                    animation-delay: .1s
                  }

                  .spinner_T5JJ {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_fLK4 1.2s linear infinite;
                    animation-delay: .1s
                  }

                  .spinner_E3Wz {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_tDji 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_g2vs {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_CMiT 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_ctYB {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_cHKR 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_BDNj {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_Re6e 1.2s linear infinite;
                    animation-delay: .3s
                  }

                  .spinner_rCw3 {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_EJmJ 1.2s linear infinite;
                    animation-delay: .3s
                  }

                  .spinner_Rszm {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_YJOP 1.2s linear infinite;
                    animation-delay: .4s
                  }

                  @keyframes spinner_5QiW {

                    0%,
                    50% {
                      width: 7.33px;
                      height: 7.33px
                    }

                    25% {
                      width: 1.33px;
                      height: 1.33px
                    }
                  }

                  @keyframes spinner_PnZo {

                    0%,
                    50% {
                      x: 1px;
                      y: 1px
                    }

                    25% {
                      x: 4px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_4j7o {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 1px
                    }

                    25% {
                      x: 11.33px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_fLK4 {

                    0%,
                    50% {
                      x: 1px;
                      y: 8.33px
                    }

                    25% {
                      x: 4px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_tDji {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 1px
                    }

                    25% {
                      x: 18.66px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_CMiT {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 8.33px
                    }

                    25% {
                      x: 11.33px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_cHKR {

                    0%,
                    50% {
                      x: 1px;
                      y: 15.66px
                    }

                    25% {
                      x: 4px;
                      y: 18.66px
                    }
                  }

                  @keyframes spinner_Re6e {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 8.33px
                    }

                    25% {
                      x: 18.66px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_EJmJ {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 15.66px
                    }

                    25% {
                      x: 11.33px;
                      y: 18.66px
                    }
                  }

                  @keyframes spinner_YJOP {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 15.66px
                    }

                    25% {
                      x: 18.66px;
                      y: 18.66px
                    }
                  }
                </style>
                <rect class="spinner_zWVm" x="1" y="1" width="7.33" height="7.33" />
                <rect class="spinner_gfyD" x="8.33" y="1" width="7.33" height="7.33" />
                <rect class="spinner_T5JJ" x="1" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_E3Wz" x="15.66" y="1" width="7.33" height="7.33" />
                <rect class="spinner_g2vs" x="8.33" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_ctYB" x="1" y="15.66" width="7.33" height="7.33" />
                <rect class="spinner_BDNj" x="15.66" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_rCw3" x="8.33" y="15.66" width="7.33" height="7.33" />
                <rect class="spinner_Rszm" x="15.66" y="15.66" width="7.33" height="7.33" />
              </svg>
            </div>
            <div *ngIf="role == 'VENDEDOR'">
              <svg class="svg-style-vendedor" width="44" height="44" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <style>
                  .spinner_zWVm {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_PnZo 1.2s linear infinite
                  }

                  .spinner_gfyD {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_4j7o 1.2s linear infinite;
                    animation-delay: .1s
                  }

                  .spinner_T5JJ {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_fLK4 1.2s linear infinite;
                    animation-delay: .1s
                  }

                  .spinner_E3Wz {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_tDji 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_g2vs {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_CMiT 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_ctYB {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_cHKR 1.2s linear infinite;
                    animation-delay: .2s
                  }

                  .spinner_BDNj {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_Re6e 1.2s linear infinite;
                    animation-delay: .3s
                  }

                  .spinner_rCw3 {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_EJmJ 1.2s linear infinite;
                    animation-delay: .3s
                  }

                  .spinner_Rszm {
                    animation: spinner_5QiW 1.2s linear infinite, spinner_YJOP 1.2s linear infinite;
                    animation-delay: .4s
                  }

                  @keyframes spinner_5QiW {

                    0%,
                    50% {
                      width: 7.33px;
                      height: 7.33px
                    }

                    25% {
                      width: 1.33px;
                      height: 1.33px
                    }
                  }

                  @keyframes spinner_PnZo {

                    0%,
                    50% {
                      x: 1px;
                      y: 1px
                    }

                    25% {
                      x: 4px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_4j7o {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 1px
                    }

                    25% {
                      x: 11.33px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_fLK4 {

                    0%,
                    50% {
                      x: 1px;
                      y: 8.33px
                    }

                    25% {
                      x: 4px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_tDji {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 1px
                    }

                    25% {
                      x: 18.66px;
                      y: 4px
                    }
                  }

                  @keyframes spinner_CMiT {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 8.33px
                    }

                    25% {
                      x: 11.33px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_cHKR {

                    0%,
                    50% {
                      x: 1px;
                      y: 15.66px
                    }

                    25% {
                      x: 4px;
                      y: 18.66px
                    }
                  }

                  @keyframes spinner_Re6e {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 8.33px
                    }

                    25% {
                      x: 18.66px;
                      y: 11.33px
                    }
                  }

                  @keyframes spinner_EJmJ {

                    0%,
                    50% {
                      x: 8.33px;
                      y: 15.66px
                    }

                    25% {
                      x: 11.33px;
                      y: 18.66px
                    }
                  }

                  @keyframes spinner_YJOP {

                    0%,
                    50% {
                      x: 15.66px;
                      y: 15.66px
                    }

                    25% {
                      x: 18.66px;
                      y: 18.66px
                    }
                  }
                </style>
                <rect class="spinner_zWVm" x="1" y="1" width="7.33" height="7.33" />
                <rect class="spinner_gfyD" x="8.33" y="1" width="7.33" height="7.33" />
                <rect class="spinner_T5JJ" x="1" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_E3Wz" x="15.66" y="1" width="7.33" height="7.33" />
                <rect class="spinner_g2vs" x="8.33" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_ctYB" x="1" y="15.66" width="7.33" height="7.33" />
                <rect class="spinner_BDNj" x="15.66" y="8.33" width="7.33" height="7.33" />
                <rect class="spinner_rCw3" x="8.33" y="15.66" width="7.33" height="7.33" />
                <rect class="spinner_Rszm" x="15.66" y="15.66" width="7.33" height="7.33" />
              </svg>
            </div>

            <!-- <span>C</span><span>a</span><span>r</span><span>g</span><span>a</span><span>n</span><span>d</span><span>o</span> -->
          </div>
        </div>

        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
