import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import {
  LogoutOutline, ProfileOutline, UserOutline,
  DollarOutline, ShoppingCartOutline, TeamOutline,
  EyeOutline,
  UnorderedListOutline,
  PlusOutline,
  UpOutline,
  CrownOutline, CheckCircleOutline, CreditCardOutline,
  BarChartOutline
} from '@ant-design/icons-angular/icons';
import { CookieService } from 'ngx-cookie-service';
import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { MainService } from './service/main.service';
import { CartQuantityResponse } from './interface/cart-quantity-response';
import { CartService } from '../../shared/services/cart.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { VendorService } from '../../shared/services/vendor.service';
import { HasRoleDirective } from '../../shared/directives/has-role.directive';
import { StatusService } from '../../shared/services/status.service';
import { userStatusResponse } from '../plan/interface/userStatusResponse';

import { SearchService } from '../../shared/services/search.service';


@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule,
    RouterOutlet,
    NzLayoutModule,
    NzMenuModule,
    RouterModule,
    NzIconModule,
    NzSpinModule,
    NzToolTipModule,
    NzButtonModule,
    NzBadgeModule,
    HasRoleDirective


  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  providers: [
    NzIconService
  ]
})
export class MainComponent implements OnInit {

  isCollapsed = false;
  role: string = "";
  isLoading: boolean = false;
  isLoadingSpinOff: boolean = false;
  quantityCart: CartQuantityResponse = { quantity: 0 };
  quantityShipped: number = 0;
  isLoadingPagination: boolean = false;
  activeSubmenu!: string; // Para manejar el submenu activo
  userStatus: string = "";
  currentUrl: string = "";
  // page: number = 0;
  // search: string = "";
  page: number = 1;
  searchQuery: string = '';
  categoryId: number = 0;
  year:number = 0;
  startDate: string = "";
  endDate: string = "";

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private iconService: NzIconService,
    private mainService: MainService,
    private cartService: CartService,
    private vendorService: VendorService,
    private cdr: ChangeDetectorRef,
    private statusService: StatusService,
    private searchService: SearchService

  ) {
    // Registrar los íconos que necesitas
    this.iconService.addIcon(...[LogoutOutline, ProfileOutline, UserOutline,
      DollarOutline, ShoppingCartOutline, TeamOutline, EyeOutline, UnorderedListOutline,
      PlusOutline, UpOutline, CrownOutline, CheckCircleOutline, TeamOutline, CreditCardOutline, BarChartOutline
    ]);

  }


  ngOnInit(): void {
    this.statusUser();
     // Nos suscribimos a los observables para obtener los valores actuales
    this.subcribeEndDateDespacho();
    this.subcribeStartDateDespacho();
    this.subcribeYearByDespacho();

     this.searchService.page$.subscribe(page => {
      this.page = page;
      console.log("Pagina numero: "+ page);
    });

    this.searchService.search$.subscribe(searchQuery => {
      this.searchQuery = searchQuery;
      console.log("Search: " + searchQuery);
    });
    this.searchService.categoryId$.subscribe(categoryId => {
      this.categoryId = categoryId;
      console.log("CategoryId: " + categoryId);
    })

    setTimeout(() => {
      this.role = this.cookieService.get('role');
      console.log("ROLE: " + this.role);
      if (this.role === 'VENDEDOR') {
        this.getQuantityCart();
        this.quantityToBeShipped();
        // Suscribirse al evento cuando el carrito se actualiza
        this.cartService.cartUpdated$.subscribe(() => {
          this.getQuantityCart();

        });
        this.vendorService.despachoUpdated$.subscribe(() => {
          this.quantityToBeShipped();
        });
      }
    }, 100)

  }

  statusUser(){
    const userId = +this.cookieService.get('userId');
    if(userId){
      this.statusService.getStatus(userId).subscribe(
        (response:userStatusResponse)=>{
          this.userStatus = response.userStatus;
        },error=>{
          console.log(error);
        }
      )
    }
  }

  // Función para cambiar la ruta y mostrar el spinner
  navigateAndShowSpinner(url: string): void {
    this.isLoading = true; // Activar el spinner
    // TODO ARREGLAR
    this.activeSubmenu = url; // Establecer el submenu activo
    this.router.navigate([url]).then(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.searchService.categoryId$.subscribe(categoryId => {
        this.categoryId = categoryId;
        console.log("CategoryId: " + categoryId);
    });

      setTimeout(() => {
        this.isLoading = false;
      }, 400);
    });

  }

   // Método para determinar si un submenu está activo
   isSubmenuActive(routes: string[]): boolean {
    // this.searchService.page$.subscribe(page => {
    //   this.page = page;
    //   console.log("Pagina numero: "+ page);
    // });

    // this.searchService.search$.subscribe(searchQuery => {
    //   this.searchQuery = searchQuery;
    //   console.log("Search: " + searchQuery);
    // });
    // this.searchService.categoryId$.subscribe(categoryId => {
    //   this.categoryId = categoryId;
    //   console.log("CategoryId: " + categoryId);
    // })
    return routes.some(route => this.router.isActive(route, true));
  }

  logout() {
    console.log("Cerrando sesión...");
    // Mostrar el spinner
    this.isLoadingSpinOff = true;

    try {
      // Eliminar las cookies
      this.cookieService.delete('userId');
      this.cookieService.delete('token');
      this.cookieService.delete('role');
      this.cookieService.delete('vendorId');
      this.cookieService.delete('cartId');
      this.cookieService.delete('userStatus');

      this.cookieService.delete('userId', '/');
      this.cookieService.delete('token', '/');
      this.cookieService.delete('role', '/');
      this.cookieService.delete('vendorId', '/');
      this.cookieService.delete('cartId', '/');
      this.cookieService.delete('userStatus', '/');
      this.cookieService.delete('userStatus', '/main/sale');
      this.cookieService.delete('userStatus', '/main/vendor');
      this.cookieService.delete('userStatus', '/main/client');
      this.cookieService.delete('userStatus', '/main/art');
      this.cookieService.delete('userStatus', '/main/zone');
      this.cookieService.delete('userStatus', '/main/seller');
      this.cookieService.delete('userStatus', '/main/owner');
      this.cookieService.delete('userStatus', '/main/admin-sales');
      this.cookieService.delete('userStatus', '/main/art/detail');
      this.cookieService.delete('userStatus', '/main/plan');
      this.cookieService.delete('userStatus', '/main/vendor-sales');
      this.cookieService.deleteAll();
      console.log('Cookies eliminadas');
    } catch (error) {
      console.error('Error eliminando cookies:', error);
      this.isLoadingSpinOff = false;
    } finally {
      // Ocultar el spinner y redirigir después de un breve retraso
      setTimeout(() => {
        this.cookieService.deleteAll();
        this.router.navigateByUrl("/auth").then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        this.router.events.subscribe((event) => {
          if(event instanceof NavigationEnd) {
            this.isLoadingSpinOff = false;
          }
        });

      }, 1000);
    }
  }



  // getQuantityCart() {
  //   const cartIdString = this.cookieService.get('cartId');
  //   const cartId = parseInt(cartIdString, 10);
  //   const vendorIsString = this.cookieService.get('vendorId');
  //   const vendorId = parseInt(vendorIsString, 10);
  //   if (cartId && vendorId != null) {

  //     this.mainService.getCartQuantityFor(cartId, vendorId).subscribe(
  //       (response: CartQuantityResponse) => {
  //         this.quantityCart = response;
  //         this.cdr.detectChanges();
  //         console.log("Cantidad en cart con id: " + cartId + " = " + response.quantity);
  //       }, (error) => {
  //         console.log(error);
  //       }

  //     )
  //   }
  // }
  getQuantityCart() {
    const cartIdString = this.cookieService.get('cartId');
    const vendorIsString = this.cookieService.get('vendorId');

    // Verifica si cartId y vendorId son válidos
    if (cartIdString && vendorIsString) {
      const cartId = parseInt(cartIdString, 10);
      const vendorId = parseInt(vendorIsString, 10);

      if (!isNaN(cartId) && !isNaN(vendorId)) {
        this.mainService.getCartQuantityFor(cartId, vendorId).subscribe(
          (response: CartQuantityResponse) => {
            this.quantityCart = response || { quantity: 0 }; // Asegúrate de que al menos obtienes 0
            this.cdr.detectChanges();
            console.log("Cantidad en cart con id: " + cartId + " = " + response.quantity);
          },
          (error) => {
            console.log("Error obteniendo la cantidad del carrito", error);
          }
        );
      }
    } else {
      // Si no hay cartId o vendorId, asegúrate de que el valor sea 0
      this.quantityCart = { quantity: 0 };
      this.cdr.detectChanges();
    }
  }


  quantityToBeShipped() {
    const vendorId = +this.cookieService.get('vendorId');
    this.vendorService.quantityToBeShipped(vendorId).subscribe(
      response => {
        console.log("Cantidad a despachar: " + response);
        this.quantityShipped = response;
        this.cdr.detectChanges();
      }, error => {
        console.log(error);
      }
    )
  }

  subcribeYearByDespacho(){
    this.searchService.year$.subscribe(year =>{
      this.year = year;
      console.log("Year obtenido desde despacho = " + year);
    });
  }

  subcribeEndDateDespacho(){
    this.searchService.endDate$.subscribe(endDate => {
      this.endDate = endDate;
      console.log("EndDate obtenido desde despacho = " + endDate);
    })
  }

  subcribeStartDateDespacho(){
    this.searchService.startDate$.subscribe(startDate => {
      this.startDate = startDate;
      console.log("startDate obtenido desde despacho = " + startDate);
    })
  }


}
