import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Directive({
  selector: '[hasRole]',
  standalone: true,

})
export class HasRoleDirective {
  private currentUserRole!: string;
  constructor(
    private cookie: CookieService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.currentUserRole = this.cookie.get('role');
  }

  @Input() set hasRole(allowedRoles: string[]) {
    if (allowedRoles.includes(this.currentUserRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }


}
