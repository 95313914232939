import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CartQuantityRequest } from '../interface/cart-quantity-request';
import { Observable } from 'rxjs';
import { CartQuantityResponse } from '../interface/cart-quantity-response';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private urlCart = `${environment.cartUrl}`;

  constructor(private http: HttpClient) { }

  public getCartQuantityFor(cartId:number, vendorId:number): Observable<CartQuantityResponse>{
    const url = `${this.urlCart}/quantity/${cartId}/vendor/${vendorId}`;
    return this.http.get<CartQuantityResponse>(url);
  }

}
